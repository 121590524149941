import * as React from 'react'
import { ButtonProps } from '../interfaces/ButtonProps'

export const Button: React.FC<ButtonProps> = ({children, type = 'button', onClick = () => {}}) => (
  <button
    type="button"
    className="block items-center px-4 py-2 border border-transparent bg-grape text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet text-center"
    onClick={onClick}
  >
    {children}
  </button>
)
